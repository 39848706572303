<template>
  <div>
    <vxe-table keep-source ref="myTbl"
        :loading="tableLoading"
        size="mini"
        :column-config="{resizable: true}"
        border
        show-overflow
        :row-config="{isCurrent: true, isHover: true}"
        :data="tableList"
        :max-height="tableHeight"
        @edit-closed="editClosedEvent"
        :cell-class-name="cellClassName"
        :tooltip-config="{theme: 'light'}"
        :edit-config="{enabled: $hasPermission('mps:sbs:edit') && locked < 1, trigger: 'click', mode: 'cell', showStatus: true, beforeEditMethod: beforeEditMethod }">
      <vxe-column type="seq" title="序号" width="70" fixed="left" align="center">
        <template #header>
          <!-- 批量添加清单 -->
<!--          <vxe-button v-if="locked < 1" content="添加" size="mini" @click="batchAdd()" status="primary"></vxe-button>-->
          <i v-if="locked < 1 && (dataForm.isLeaf || dataForm.isSbsLeaf)" class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;font-size: 25px" @click="batchAdd()"></i>
        </template>
      </vxe-column>
      <vxe-column field="boqCode" title="清单编号" width="150" fixed="left"></vxe-column>
      <vxe-column field="boqName" title="清单名称" min-width="160" fixed="left"></vxe-column>
      <vxe-column field="unitName" title="单位" align="center" width="80"></vxe-column>
      <vxe-column field="typeId" title="类型" align="center" width="90">
        <template #default="{row}">
          <el-tag v-if="row.typeId == 1" type="success">合同清单</el-tag>
          <el-tag v-if="row.typeId == 2" type="danger">数量变更</el-tag>
          <el-tag v-if="row.typeId == 3" type="danger">新增清单</el-tag>
          <el-tag v-if="row.typeId == 4" type="danger">暂定清单</el-tag>
        </template>
      </vxe-column>
      <vxe-column field="initNum" title="合同数量" width="140" align="right" :edit-render="{ name: 'MyInputNumber', props: {digitsKey: 'accuracy'} }"></vxe-column>
      <!-- <vxe-column field="checkedNum" title="修编后数量" align="right" width="140" :formatter="({cellValue, row}) => numberForamt(cellValue, row.accuracy)"></vxe-column> -->
      <vxe-column field="checkedChangedNum" title="修编增减" width="140" align="right" :formatter="({cellValue, row}) => numberForamt(cellValue, row.accuracy)"></vxe-column>
      <vxe-column field="num" title="变更后数量" width="140" align="right" :formatter="({cellValue, row}) => numberForamt(cellValue, row.accuracy)"></vxe-column>
      <vxe-column field="changeOrderCode" title="变更令编号" min-width="160"></vxe-column>
      <vxe-column title="操作" width="80" align="center" fixed="right">
        <template #default="{row}">
          <el-button v-if="$hasPermission('mps:sbs:edit') && locked <1 && row.typeId==1" type="text" size="small" @click="deleteSubBoqHandle(row)">{{ $t('delete') }}</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 弹窗，批量新增 -->
    <sbs-edit-boq-select v-if="batchAddBoqVisible" ref="batchAddBoq" @refreshDataList="loadData" @close="closeDialogHandle"></sbs-edit-boq-select>
  </div>
</template>
<!--标段分项清单编制-右下清单明细页面-->
<script>
import XEUtils from 'xe-utils'
import SbsEditBoqSelect from './sbs-edit-boq-select'
export default {
  data () {
    return {
      dataForm: {
        prjId: '',
        contractId: '',
        subcontractorId: '',
        subItemId: '',
        subItemLevel: '',
        isLeaf: '',
        isSbsLeaf: '',
      },
      tableLoading: false,
      tableList: [],
      batchAddBoqVisible: false,
    }
  },
  props: {
    tableHeight: Number,
    locked: Number,
  },
  components: {
    SbsEditBoqSelect
  },

  methods: {
    init() {
      this.$nextTick(() => {
        if (this.dataForm.subItemId) {
          this.loadData()
        }
      })
    },
    clear() {
      this.tableList = []
    },
    loadData() {
      this.tableLoading = true
      this.$http.get('/mps/sbs/subBoq', {
          params: {
            ...this.dataForm
          }
        }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.tableList = res.data
        if (res.data == null || res.data.length == 0) {
          // this.$message({
          //   message: '未查询到相关的分项清单。',
          //   type: 'warning',
          //   duration: 2000,
          // })
        }
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.tableLoading = false
      })
    },
    
    // 删除分项清单
    deleteSubBoqHandle(row) {
      this.$confirm(this.$t('prompt.info', {'handle': this.$t('delete')}), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        // 校验该分项清单是否已参与计量，如果已参与计量则不允许删除
        this.$http.get(`/mps/meas/isSubItemAndBoqMeasured/${row.subItemId}/${row.boqId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (res.data) {
            return this.$message.error("该分项清单已参与计量，不允许删除！")
          }

          this.$http.delete(`/mps/sbs/subBoq`,
              {
                'data': [row.id]
              }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.loadData()
                // 用户填写了分项清单的工程量以后，需要触发分项清单合同金额字段的自动计算
                this.$emit('calculateSbsMoney', this.dataForm.subItemId)
              }
            })
          }).catch()
        }).catch(() => {
          this.$message.error('出错了！')
        })
      }).catch()
    },
    // 批量新增
    batchAdd() {
      this.batchAddBoqVisible = true
      this.$nextTick(() => {
        this.$refs.batchAddBoq.dataForm.prjId = this.dataForm.prjId
        this.$refs.batchAddBoq.dataForm.contractId = this.dataForm.contractId
        this.$refs.batchAddBoq.dataForm.subcontractorId = this.dataForm.subcontractorId
        this.$refs.batchAddBoq.dataForm.subItemId = this.dataForm.subItemId
        this.$refs.batchAddBoq.init()
      })
    },
    // 关闭弹窗时将其销毁
    closeDialogHandle() {
      this.batchAddBoqVisible = false
    },
    // 单元格退出编辑事件
    editClosedEvent({row, column}) {
      const $table = this.$refs.myTbl
      const field = column.property
      // 判断单元格值是否被修改
      if ($table.isUpdateByRow(row, field)) {
        if (field === 'initNum') {
          this.$http.put('/mps/sbs/subBoqInitNum', {
            id: row.id,
            initNum: row.initNum
          }).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                // 填写分项清单工程量后，需触发自动计算分项清单的合同金额
                this.$emit('calculateSbsMoney', this.dataForm.subItemId)
              }
            })
          }).catch()
        }
      }
    },
    numberForamt(val, digits) {
      if (XEUtils.isNumber(digits)) {
        return XEUtils.commafy(val, { digits: digits })
      } else {
        return val
      }
    },

    // 单元格是否允许编辑
    cellCanEdit(row, column){
      return this.$hasPermission('mps:sbs:edit') 
          && this.locked < 1
          && column.property === 'initNum' && row.typeId == 1 && this.dataForm.subItemLevel != 99 // level为99代表该节点是修编细目，此时的零号清单合同数量是不允许填写的
    },

    // 单元格是否允许编辑
    beforeEditMethod ({row, rowIndex, column, columnIndex}) {
      return this.cellCanEdit(row, column)
    },
    
    cellClassName({row, column}) {
      if (this.cellCanEdit(row, column)) {
        return 'cell-underline'
      } else {
        return ''
      }
    },
  }
}
</script>
