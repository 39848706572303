<template>
  <div class="ren-region">
    <el-tooltip effect="light" :content="pnames" placement="top">
      <el-input v-model="showWbsName" :placeholder="placeholder" @focus="showDialog">
        <el-button slot="append" icon="el-icon-search" @click="showDialog"></el-button>
      </el-input>
    </el-tooltip>
    <el-input :value="value" style="display: none"></el-input>
    <el-dialog :visible.sync="visibleTree" width="40%" :modal="true" :title="placeholder" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true">
      <el-form size="mini" :inline="true">
        <el-form-item :label="$t('keyword')">
          <el-input v-model="filterText"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="default">{{ $t('query') }}</el-button>
        </el-form-item>
      </el-form>
      <el-tree
        v-loading="wbsTreeLoading"
        class="filter-tree"
        :data="dataList"
        :default-expanded-keys="expandedKeys"
        :props="{ label: 'name', children: 'children' }"
        :filter-node-method="filterNode"
        :highlight-current="true"
        node-key="id"
        ref="tree">
      </el-tree>
      <template slot="footer">
        <el-button type="default" @click="wbsSelectCloseHandle" size="mini">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="wbsSelectSubmitHandle" size="mini">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      filterText: '',
      visibleTree: false,
      dataList: [],
      showWbsName: '',
      expandedKeys: null,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      pnames: '',
      wbsTreeLoading: false,
    }
  },
  props: {
    prjId: '',
    value: [Number,String],
    wbsName: String,
    wbsPnames: String,
    placeholder: String
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    },
    value(val){
      this.showWbsName = this.wbsName
      if(this.wbsName){
        if (this.wbsPnames) {
          this.pnames = this.wbsPnames + "," + this.wbsName
        } else {
          this.pnames = this.wbsName
        }
      }
    }
  },
  methods: {
    showDialog () {
      if (this.$refs.tree) {
        this.$refs.tree.setCurrentKey(null)
      }
      this.dataList = []
      this.filterText = ''
      this.expandedKeys = []
      this.visibleTree = true
      this.getDataList(this.value)
    },
    filterNode (value,data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    getDataList (id) {
      this.wbsTreeLoading = true
      // 由于MPS计量支付平台的wbs是放在标段或是标段下的工区上创建的，图纸列表上只是选择了项目ID，
      // 所以这里要根据项目ID查询出该用户授权的标段集合,然后再查询出标段上的wbs。
      this.$http.get(
        '/mps/contract/openapi/briefs',
        {
          params: {
            prjId: this.prjId,
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$messge.error(res.msg)
        }

        let contractList = res.data || []
        // 循环标段，查询标段下的wbs
        for (let i = 0; i < contractList.length; i++) {
          let contractObj = contractList[i]
          this.$http.get('/mps/wbs/openapi/tree',{
            params: {
              contractId: contractObj.id
            }
          }).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            res.data = res.data || []
            if (res.data.length > 0) {
              this.dataList.push.apply(this.dataList, res.data)
            }
          }).catch(() => {
            return this.$message.error('出错了')
          }).finally(() => {
            this.$nextTick(() => {
              if (id != null) {
                this.$refs.tree.setCurrentKey(id)
                this.expandedKeys = [id]
              }
            })
          })
        }
      }).catch(() => {
        // do nothing
      }).finally(() => {
        this.wbsTreeLoading = false
      })
    },
    wbsSelectCloseHandle () {
      this.visibleTree = false
      this.dataList = []
      this.filterText = ''
    },
    wbsSelectSubmitHandle: debounce(function () {
      const node = this.$refs.tree.getCurrentNode()
      if (!node) {
        this.$message.error("请选择分部分项工程！")
        return
      }
      this.$emit('update:wbsName', node.name)
      this.$emit('update:wbsPnames', node.pnames)
      this.showWbsName = node.name
      this.visibleTree = false
      this.dataList = []
      this.filterText = ''
      this.$emit('input', node.id)

      // 图纸目录变化事件
      this.$emit('change', node.id)

    },1000,{'leading': true,'trailing': false}),
  }
}
</script>
<style lang="scss">
.ren-region {
    .filter-tree {
        max-height: 330px;
        overflow: auto;
    }
    .el-dialog__body {
        padding: 0px 0px 0px 20px;
    }
    .el-dialog__footer {
        padding: 10px 20px 8px 20px;
    }
}
</style>