<template>
  <el-dialog top="5vh" :visible.sync="visible" title="导入" :close-on-click-modal="false" :close-on-press-escape="false">
    <el-radio-group v-model="importType" @input="changeImportType">
      <el-radio label="1">导入格式1（不含清单修编）</el-radio>
      <el-radio label="2">导入格式2（不含清单修编）</el-radio>
      <el-radio label="3">导入格式3（含有清单修编）</el-radio>
    </el-radio-group>
    <el-row>
      <el-col :span="24">
        <img :src="thumbnail" style="width: 100%;">
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-upload
          name="file"
          :action="actionUrl"
          :file-list="fileList"
          drag
          :before-upload="beforeUploadHandle"
          :on-success="successHandle"
          :on-error="errorHandle"
          class="text-center my-excel-import-dialog">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text" v-html="$t('upload.text')"></div>
        </el-upload>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
// 分项清单导入（兼容两种模板格式，一种含有清单修编子目，一种不含清单修编）
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      actionUrl: '',
      contractId: '',
      subcontractorId: '',
      visible: false,
      fileList: [],
      importLoading: {},
      // 兼容不同的导入格式
      importType: '1',
      thumbnail: require(`@/assets/img/import/import_sbs_subboq1.jpg`)
    }
  },
  methods: {
    init (contractId, subcontractorId) {
      this.contractId = contractId
      this.subcontractorId = subcontractorId
      this.visible = true
      this.fileList = []
    },
    changeImportType() {
      if (this.importType == 1) {
        this.thumbnail = require(`@/assets/img/import/import_sbs_subboq1.jpg`)
      } else if (this.importType == 2) {
        this.thumbnail = require(`@/assets/img/import/import_sbs_subboq2.jpg`)
      } else if (this.importType == 3) {
        this.thumbnail = require(`@/assets/img/import/import_sbs_subboq3.jpg`)
      }
      // 重新设置importType参数
      this.actionUrl = `${window.SITE_CONFIG['apiURL']}/mps/sbs/import?access_token=${Cookies.get('access_token')}&importType=${this.importType}&contractId=${this.contractId}&subcontractorId=${this.subcontractorId}`
    },
    beforeUploadHandle (file) {
      this.importLoading = this.$loading({
        text: '正在导入，请稍等...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    successHandle (res, file, fileList) {
      this.importLoading.close()
      this.visible = false
      if (res.code !== 0) {
        return this.$alert(res.msg, '错误信息', {
          confirmButtonText: '关闭',
        })
      }
      return this.$alert('已导入结束！', '操作结果', {
        confirmButtonText: '关闭',
        callback: () => {
          this.$emit('refreshDataList')
        }
      })
    },
    errorHandle(err) {
      this.importLoading.close()
      this.visible = false
      this.$emit('refreshDataList')
      console.log('导入分项清单过程中出现错误', err); 
    },
  }
}
</script>
<style scoped>
.my-excel-import-dialog {
  margin-top: 60px;
  margin-bottom: 90px;
}
</style>