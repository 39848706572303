<template>
  <div class="ren-region">
    <el-input v-model="showCatalogName" :placeholder="placeholder" @focus="showDialog">
      <el-button slot="append" icon="el-icon-search" @click="showDialog"></el-button>
    </el-input>
    <el-input :value="value" style="display: none"></el-input>
    <el-dialog :visible.sync="visibleTree" width="40%" :modal="true" :title="placeholder" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true">
      <el-form size="mini" :inline="true">
        <el-form-item :label="$t('keyword')">
          <el-input v-model="filterText"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="default">{{ $t('query') }}</el-button>
        </el-form-item>
      </el-form>
      <el-tree
        class="filter-tree"
        :data="dataList"
        :default-expanded-keys="expandedKeys"
        :props="{ label: 'name', children: 'children' }"
        :filter-node-method="filterNode"
        :highlight-current="true"
        node-key="id"
        ref="tree">
      </el-tree>
      <template slot="footer">
        <el-button type="default" @click="cancelHandle()" size="mini">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="commitHandle()" size="mini">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      filterText: '',
      visibleTree: false,
      dataList: [],
      showCatalogName: '',
      expandedKeys: null,
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  props: {
    prjId: '',
    value: [Number, String],
    catalogName: String,
    placeholder: String
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    },
    catalogName (val) {
      this.showCatalogName = val
    }
  },
  methods: {
    showDialog () {
      this.expandedKeys = null
      if (this.$refs.tree) {
        this.$refs.tree.setCurrentKey(null)
      }
      this.visibleTree = true
      this.getDataList(this.value)
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    getDataList (id) {
      return this.$http.get('/mps/paperCatalog/treeList', {
        params: {
          prjId: this.prjId,
        }
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        this.$nextTick(() => {
          if(id!=null){
            this.$refs.tree.setCurrentKey(id)
            this.expandedKeys = [id]
          }
        })
      }).catch(() => {})
    },
    cancelHandle () {
      this.visibleTree = false
      this.dataList = []
      this.filterText = ''
    },
    commitHandle:debounce(function() {
      const node = this.$refs.tree.getCurrentNode()
      if (!node) {
        this.$message.error("请选择图纸目录！")
        return
      }
      this.$emit('input', node.id)
      this.$emit('update:catalogName', node.name)
      // 图纸目录变化事件
      this.$emit('change', node.id)
      this.showCatalogName = node.name
      this.visibleTree = false
      this.dataList = []
      this.filterText = ''
    },1000,{'leading': true,'trailing': false}),
  }
}
</script>
<style lang="scss">
.ren-region {
    .filter-tree {
        max-height: 330px;
        overflow: auto;
    }
    .el-dialog__body {
        padding: 0px 0px 0px 20px;
    }
    .el-dialog__footer {
        padding: 10px 20px 8px 20px;
    }
}
</style>