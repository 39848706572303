<template>
  <el-dialog top="5vh" width="80%" :visible.sync="visible" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <div class="my-tree-contianer">
      <el-tree
          v-loading="treeLoading"
          node-key="id"
          :props="props"
          ref="tree"
          lazy
          show-checkbox
          :load="load"
          :default-checked-keys="defaultCheckedKeys"
          highlight-current
          class="my-el-tree"
      >
      </el-tree>
    </div>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        contractId: '',
        subcontractorId: '',
        subItemId: '',
      },
      props: {
        //label: 'name',
        label: 'title', // dto中新增加个title属性（编码+名称）
        isLeaf: 'isLeaf',
        disabled: this.checkBoxDisabled
      },
      treeLoading: true,
      defaultCheckedKeys: [],
      title: '',
    }
  },
  computed: {
    dataRule() {
      return {}
    }
  },
  methods: {
    init() {
      this.visible = true
      this.getExistList()
    },

    getExistList() {
      this.$http.get(`/mps/sbs/bindBoqIds/${this.dataForm.subItemId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.defaultCheckedKeys = res.data
        this.treeLoading = false
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },

    // 如果展开清单非叶节点，则加载下级清单
    // 如果展开清单叶节点, 则加载该清单已分解的零号清单
    load(node, resolve) {
      let pid
      if (!node.data) {
        pid = 0
      } else {
        pid = node.data.id
      }
      this.$http.get('/mps/boq/children',
          {
            params: {
              'contractId': this.dataForm.contractId,
              'pid': pid,
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        resolve(res.data);
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },

    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      let checkedNodes = this.$refs.tree.getCheckedNodes(true)
      let newIdList = []
      // 将wbsId和boqId传至后台，避免了后台还需要通过id去查询
      for (let node of checkedNodes) {
        if (!this.defaultCheckedKeys.includes(node.id)) {
          newIdList.push(node.id)
        }
      }
      let params = {
        contractId: this.dataForm.contractId,
        subcontractorId: this.dataForm.subcontractorId,
        subItemId: this.dataForm.subItemId,
        newIdList: newIdList
      }
      this.$http.post('/mps/sbs/batchBindBoq',
          params
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList')
          }
        })
      }).catch(() => {
      })
    }, 1000, {'leading': true, 'trailing': false}),
    checkBoxDisabled(data, node) {
      return !data.isLeaf || this.defaultCheckedKeys.includes(data.id)
    },
  }
}
</script>
<style scoped>
.my-tree-contianer {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
}
</style>